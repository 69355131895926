@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    @apply bg-gray-50 dark:bg-gray-900;

    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

section {
    @apply bg-gray-50 dark:bg-gray-900;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

input:focus {
    outline: none;
}

@layer components {
    /* .my-react-select-container {
  } */
    .my-react-select-container {
    }
    .my-react-select-container .my-react-select__control {
        @apply border-2 border-gray-300 bg-white hover:border-gray-400 dark:border-gray-700 dark:bg-gray-700 dark:hover:border-gray-500;
        border-radius: 0.5rem;
    }

    .my-react-select-container .my-react-select__control--is-focused {
        @apply border-gray-500 shadow-none hover:border-gray-500 dark:border-gray-400 dark:hover:border-gray-400;
    }

    .my-react-select-container .my-react-select__menu {
        @apply border-2 border-gray-300 bg-gray-100 dark:border-gray-600 dark:bg-gray-700;
        border-radius: 0.5rem;
    }

    .my-react-select-container .my-react-select__option {
        @apply bg-gray-100 text-gray-600 hover:bg-gray-200 dark:bg-gray-700 dark:text-gray-200 dark:hover:bg-gray-800;
        border-radius: 0.5rem;
    }
    /* .my-react-select-container .my-react-select__option--is-focused {
    @apply bg-gray-200 dark:bg-gray-800;
  } */

    .my-react-select-container .my-react-select__indicator-separator {
        @apply bg-gray-400;
    }

    .my-react-select-container .my-react-select__input-container,
    .my-react-select-container .my-react-select__placeholder,
    .my-react-select-container .my-react-select__single-value {
        @apply text-gray-600 dark:text-gray-200;
    }

    .my-react-select-container .my-react-select__multi-value {
        padding: 0;
        @apply rounded bg-blue-100 text-sm font-medium text-blue-800 dark:bg-blue-900 dark:text-blue-300;
    }
}

/* .my-react-select__value-container {
  gap: 5px;
} */

/* Customizes the whole scrollbar */
::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
    border-radius: 8px; /* Rounded corners */
}

/* Customizes the track of the scrollbar */
::-webkit-scrollbar-track {
    background: #f1f1f109; /* Color of the track */
}

/* Customizes the thumb of the scrollbar */
::-webkit-scrollbar-thumb {
    background: #8888884d; /* Color of the thumb */
    border-radius: 8px; /* Rounded corners */
}

/* Changes the thumb color on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555; /* Darker color on hover */
}

@media screen and (max-width: 1024px) {
    .headerLink {
        width: 100%;
    }
}
